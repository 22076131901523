<template>
  <div class="login">
    <div class="d-flex flex-column-fluid flex-center">
      <!--begin::Signin-->
      <div class="login-page mx-auto">
        <div class="login-box">
          <div class="login-logo">
            <a href="../../index2.html"><b>Admin</b>LTE</a>
          </div>
          <div class="card border-0">
            <div class="card-body login-card-body">
              <form
                class="form"
                novalidate="novalidate"
                id="kt_login_signin_form"
                @submit.stop.prevent="onSubmitLogin()"
              >
                <p class="login-box-msg"
                  >You are only one step a way from your new password, recover
                  your password now.</p
                >
                <div class="form-group">
                  <div class="input-group mb-3">
                    <input
                      type="password"
                      class="form-control"
                      placeholder="Password"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                      </div>
                    </div>
                  </div>
                  <div class="input-group mb-3">
                    <input
                      type="password"
                      class="form-control"
                      placeholder="Confirm password"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                      </div>
                    </div>
                  </div>
                  <div class="row no-gutters">
                    <div class="col col-12">
                      <button
                        ref="kt_login_signin_submit"
                        class="btn btn-primary font-weight-bolder"
                        style="width: 100%; font-size: 16px"
                      >
                        Change password
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <p class="mb-1">
                <v-list-item
                  :ripple="false"
                  link
                  :to="{ name: 'login' }"
                  class="px-0 font-weight-bolder"
                >
                  Login
                </v-list-item>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-list-item {
  min-height: unset;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #007bff;
  &:before {
    display: none;
  }
  &:hover {
    color: #0056b3;
  }
}
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('auth')
import Swal from 'sweetalert2'

export default {
  name: 'RecoverPassword',
  data() {
    return {
      state: 'signin',
      // Remove this dummy login info
      form: {
        email: '',
        password: '',
      },
      baseUrl: this.$store.state.auth.API_URL,
    }
  },
  computed: {
    ...mapState(['errors']),
    ...mapGetters(['currentUser']),

    backgroundImage() {
      return this.baseUrl + 'media/svg/illustrations/login-visual-1.svg'
    },
  },
  methods: {
    ...mapActions(['logout', 'login', 'register']),
    showForm(form) {
      this.state = form
      var form_name = 'kt_login_' + form + '_form'
    },

    onSubmitLogin() {
      this.fv.validate()

      this.fv.on('core.form.valid', () => {
        var email = this.form.email
        var password = this.form.password

        // clear existing errors
        this.logout()

        // set spinner to submit button
        const submitButton = this.$refs['kt_login_signin_submit']
        submitButton.classList.add('spinner', 'spinner-light', 'spinner-right')

        // dummy delay
        setTimeout(() => {
          // send login request
          this.login({ email, password })
            // go to which page after successfully login
            .then(() => this.$router.push({ name: 'dashboard' }))
            .catch(() => {})

          submitButton.classList.remove(
            'spinner',
            'spinner-light',
            'spinner-right'
          )
        }, 2000)
      })

      this.fv.on('core.form.invalid', () => {
        Swal.fire({
          title: '',
          text: 'Please, provide correct data!',
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary',
          heightAuto: false,
        })
      })
    },

    onSubmitRegister() {
      this.fv1.validate()

      this.fv1.on('core.form.valid', () => {
        const email = this.$refs.remail.value
        const password = this.$refs.rpassword.value

        // clear existing errors
        this.logout()

        // set spinner to submit button
        const submitButton = this.$refs['kt_login_signup_submit']
        submitButton.classList.add('spinner', 'spinner-light', 'spinner-right')

        // dummy delay
        setTimeout(() => {
          // send register request
          this.register({
            email: email,
            password: password,
          }).then(() => this.$router.push({ name: 'dashboard' }))

          submitButton.classList.remove(
            'spinner',
            'spinner-light',
            'spinner-right'
          )
        }, 2000)
      })

      this.fv1.on('core.form.invalid', () => {
        Swal.fire({
          title: '',
          text: 'Please, provide correct data!',
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary',
          heightAuto: false,
        })
      })
    },
  },
}
</script>
